<template>
<span>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Mon badge</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Mon Badge</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="animated">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-success">
            <article class="tile is-child box" @click="modal=true">
              <barcode 
              v-bind:value="mesinfos.barcode" 
              format="ean13" 
              text-margin="0"
              font-options="bold"
              
              >
                Code invalide
              </barcode>
            </article>
          </div>
        </div>
   
      </div>
      
    </div>
  </section>
<div class="modal" v-bind:class="{ 'modal':true, 'is-active': modal }" @click="modal=false">
  <div class="modal-background"></div>
  <div class="modal-content">
  <img id="maPhoto" :src="image" @error="setDefaultImage" class="photo" alt="Ma photo">
              
      <barcode 
              v-bind:value="mesinfos.barcode" 
              format="ean13" 
              text-margin="0"
              font-options="bold"

              >
                Code invalide
              </barcode>

  </div>

</div>
  </span>
</template>

<script>
import VueBarcode from "vue-barcode";

export default {
  name: "Badge",
  data: function() {
    return {
      mesinfos: "",
      modal:false,
      image: require("../../assets/user.jpg")
    };
  },
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.image = "api/members/" + this.mesinfos.id + "/image";
    //this.init()
  },
  methods: {
    init: function() {
      window.scrollTo(0,0);
    },
    setDefaultImage: function() {
      this.image = require("../../assets/user.jpg");
    }
  },
      
  components: {
    barcode: VueBarcode
  }
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .modal {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
</style>
